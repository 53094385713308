import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import useHubspotForm from "../hooks/useHubspotForm";

const Tippgeber = () => {
    const { t } = useTranslation();

    const ref = useHubspotForm("153682b2-83bf-4711-9a56-5d4d11510c41");

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />

            <Container ref={ref} />
            <BreadcrumbList page="tippgeber" />
        </Layout>
    );
};

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 3rem auto 3rem auto;
`;

export default Tippgeber;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "homeHeader", "page.tippgeber"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
